<template>
  <v-row style="height: 100%">
    <v-col
      cols="12"
      lg="8"
      md="6"
      class="ma-0 pa-5 d-flex flex-column align-center justify-center light-blue lighten-5"
      v-if="$vuetify.breakpoint.mdAndUp"
      style="height: 100vh"
    >
      <v-img
        contain
        style="height: 100vw; width: 100vw; max-width: 600px; max-height: 600px"
        class="auth-3d-group"
        :src="require(`@/assets/images/logo.png`)"
      ></v-img>
    </v-col>
    <v-col
      cols="12"
      lg="4"
      md="6"
      class="ma-0 pa-3 d-flex flex-column align-center justify-center"
    >
      <span
        v-if="isInLoginMode"
        class="ma-0 pa-10 d-flex flex-column align-center justify-center"
        style="width: 100%"
      >
        <h1 class="text-left mb-3" style="width: 100%">Bienvenue ! 👋🏻</h1>
        <p class="text-left mb-3" style="width: 100%">
          Connectez vous pour acceder a votre dashboard
        </p>
        <v-text-field
          class="pa-0"
          v-model="loginEmail"
          label="Email"
          style="width: 100%"
        ></v-text-field>
        <v-text-field
          class="pa-0"
          v-model="loginPassword"
          type="password"
          label="Mot de passe"
          style="width: 100%"
        ></v-text-field>
        <v-btn
          class="mt-5"
          style="
            padding: 2em 3em;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 2.5px;
            font-weight: 500;
            color: #fff;
            background-color: #016dd9;
            border: none;
            border-radius: 45px;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease 0s;
            cursor: pointer;
            outline: none;
            width: 100%;
          "
          @click="login"
          >Se connecter</v-btn
        >
        <p class="ma-0 pa-3">Ou</p>
        <v-btn
          style="
            padding: 2em 3em;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 2.5px;
            font-weight: 500;
            background-color: #23c483;
            border: none;
            border-radius: 45px;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease 0s;
            cursor: pointer;
            outline: none;
            width: 100%;
          "
          class="text-center"
        >
          <a
            href="#"
            style="color: #fff; width: 100%"
            @click.prevent.stop="isInLoginMode = false"
            >S'inscrire</a
          >
        </v-btn>
      </span>

      <span
        v-else
        class="ma-0 pa-10 d-flex flex-column align-center justify-center"
        style="width: 100%"
      >
        <h1 class="text-left mb-3" style="width: 100%">Bienvenue ! ✍🏻</h1>
        <p class="text-left mb-3" style="width: 100%">
          Inscrivez vous pour acceder a votre dashboard
        </p>
        <v-text-field
          class="pa-0"
          v-model="registerEmail"
          label="Email"
          style="width: 100%"
        ></v-text-field>
        <v-text-field
          class="pa-0"
          v-model="registerPassword"
          type="password"
          label="Mot de passe"
          style="width: 100%"
        ></v-text-field>
        <v-text-field
          class="pa-0"
          v-model="registerRePassword"
          type="password"
          label="Confirmer le mot de passe"
          style="width: 100%"
        ></v-text-field>
        <v-checkbox
          class="pb-5"
          v-model="registrationConditionsAreAccepted"
          label="J'accepte que mon adresse e-mail soit collectée dans le but d'utiliser les services de Ludimation. Elle ne pourra être utilisée à des fins commerciales et/ou en dehors du cadre de l'utilisation desdits services."
          color="primary"
          hide-details
        ></v-checkbox>
        <v-btn
          color="accent"
          class="mt-5"
          style="
            width: 100%;
            padding: 2em 3em;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 2.5px;
            font-weight: 500;
            background-color: #23c483;
            border: none;
            border-radius: 45px;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease 0s;
            cursor: pointer;
            outline: none;
          "
          :disabled="!registrationIsFilled"
          @click="register"
          >S'inscrire</v-btn
        >
        <p class="ma-0 pa-3">Ou</p>
        <v-btn
          style="
            padding: 2em 3em;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 2.5px;
            font-weight: 500;
            color: #fff;
            background-color: #016dd9;
            border: none;
            border-radius: 45px;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease 0s;
            cursor: pointer;
            outline: none;
            width: 100%;
          "
          class="text-center"
        >
          <a
            style="color: #fff; width: 100%"
            href="#"
            @click.prevent.stop="isInLoginMode = true"
            >J'ai déjà un compte</a
          >
        </v-btn>
      </span>
    </v-col>
  </v-row>
</template>

<style>
html {
  overflow: hidden;
}
</style>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

export default {
  name: "Login",
  setup() {
    return {
      auth: getAuth(),
    };
  },
  data() {
    return {
      isInLoginMode: true,
      loginEmail: "",
      loginPassword: "",
      registerEmail: "",
      registerPassword: "",
      registerRePassword: "",
      registrationConditionsAreAccepted: false,
    };
  },
  computed: {
    registrationIsFilled() {
      return (
        this.registrationConditionsAreAccepted &&
        this.registerEmail.trim() != "" &&
        this.registerPassword.trim() != "" &&
        this.registerRePassword.trim() != "" &&
        this.registerPassword == this.registerRePassword
      );
    },
  },
  methods: {
    login() {
      signInWithEmailAndPassword(getAuth(), this.loginEmail, this.loginPassword)
        .then((userCredential) => {
          this.$router.push("/");
        })
        .catch((error) => {
          this.loginEmail = "";
          this.loginPassword = "";
          Swal.fire({
            title: "Email et/ou mot de passe incorrect",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    },
    register() {
      createUserWithEmailAndPassword(
        this.auth,
        this.registerEmail,
        this.registerPassword
      )
        .then(() => {
          this.auth.signOut();
          Swal.fire({
            title: "Inscription réussie",
            text: "Vous pouvez maintenant vous connecter",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            this.loginEmail = "";
            this.loginPassword = "";
            this.isInLoginMode = true;
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Erreur",
            text: error.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    },
  },
};
</script>
